import { Router } from '@reach/router';
import { styled } from '@mui/material/styles';

/*
   Reach Router inserts a <div>
     https://github.com/reach/router/issues/63
     <div tabindex="-1" style="outline: none; height: 100%;">
   
   We cannot easily switch away from Reach router because we are using Gatsby. 
 */
export default styled(Router)(({ theme }) => ({
    height: '100%'
}));