import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
  Typography
} from '@mui/material';
import React, { createContext, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ScrollableBox from './ScrollableBox';
import { styled } from '@mui/material/styles';

const LeftColumn = styled((props) => <Paper elevation={5} {...props}></Paper>)(
  ({ theme }) => ({
    minWidth: 300,
    width: 300,
    marginRight: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  })
);

const ColumnBox = ({ children, sx }) =>
  <Box
    sx={{
      m: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    }}>
    <ScrollableBox sx={{ ...sx }}>
      {children}
    </ScrollableBox>
  </Box>

const ColumnBoxHeader = ({ children }) =>
  <Box sx={{ mt: 0, mr: 0.8, mb: 0, ml: 2 }}>
    <Typography
      gutterBottom
      variant="body1"
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
      {children}
    </Typography>
  </Box>

const AccordionContext = createContext();

const ColumnAccordion = ({ title, children, sx, onlyClickableIcon }) => {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionStyles = {
    flexShrink: 1,
    mr: 0,
    '& .MuiAccordionSummary-root': {
      pr: 1.3,
    },
    ...sx
  }

  return (
    <Accordion
      expanded={expanded === 'panel1'}
      sx={accordionStyles}
      {...(!onlyClickableIcon ? { onChange: handleChange('panel1') } : {})}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            {...(onlyClickableIcon ? {
              onClick: () => {
                setExpanded(!expanded ? 'panel1' : false);
              }
            } : {})}
          />
        }
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>
        <AccordionContext.Provider value={{ closeAccordion: () => setExpanded(false) }}>
          {children}
        </AccordionContext.Provider>
      </AccordionDetails>
    </Accordion>
  );
};

ColumnAccordion.defaultProps = {
  onlyClickableIcon: false
}

LeftColumn.ColumnBox = ColumnBox;
LeftColumn.ColumnBoxHeader = ColumnBoxHeader;
LeftColumn.ColumnAccordion = ColumnAccordion;
LeftColumn.AccordionContext = AccordionContext;

export default LeftColumn;
