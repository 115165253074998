export const ellipseAddress = (address = "", width = 10) => `${address.slice(0, width)}...${address.slice(-width)}`;

// Rarity
export const WEIGHT_LEGENDARY = 1
export const WEIGHT_EPIC = 2
export const WEIGHT_RARE = 3
export const WEIGHT_UNCOMMON = 4
export const WEIGHT_NORMAL = 5

export const rarityByWeight = {
  [WEIGHT_LEGENDARY]: "Legendary",
  [WEIGHT_EPIC]: "Epic",
  [WEIGHT_RARE]: "Rare",
  [WEIGHT_UNCOMMON]: "Uncommon",
  [WEIGHT_NORMAL]: "Normal",
}

const rarityColorByWeight = {
  [WEIGHT_LEGENDARY]: "#ff8400",
  [WEIGHT_EPIC]: "#ae00ff",
  [WEIGHT_RARE]: "#006aff",
  [WEIGHT_UNCOMMON]: "#00ff22",
  [WEIGHT_NORMAL]: "#ffffff",
}

export const weightToRarityColor = (weight) => rarityColorByWeight[weight];
export const weightToRarity = (weight) => rarityByWeight[weight];

// Array Helpers
export const uniqueItems = (items, uniqueKey) => {
  let getItemKey;
  if (typeof uniqueKey === 'function') {
    getItemKey = uniqueKey
  } else {
    getItemKey = (item) => item[uniqueKey]
  }
  return [
    ...new Map(items.map((item) => [getItemKey(item), item])).values(),
  ];
}

// Combination Helpers
export const getCombinationDna = (combination) => getVariationsWithComponents((combination || [])).map(variation => variation.id).join('-')

const getVariationComponentId = (resource) => {
  switch (resource.__typename) {
    case 'Variation':
      if (resource.parentVariation?.id) {
        return resource.parentVariation.id;
      }
      return resource.id;
    case 'Layer':
      if (resource.variations.length) {
        const variationComponent = resource.variations[0]
        return getVariationComponentId(variationComponent);
      }
      break;
    default:
      break;
  }
  return undefined;
}

// Copypasta: https://stackoverflow.com/a/20129594/18216936
const selectColor = (number) => {
  const hue = number * 137.5077640500378546463487; // Gold angle approximation
  return `hsl(${hue},100%,70%)`;
}

// Copypasta: http://jsfiddle.net/sUK45/
export const getResourceColor = (resource) => {
  const resourceId = getVariationComponentId(resource);
  if (!resourceId) {
    return undefined;
  }
  return selectColor(resourceId);
}

export const isResourceVariationComponent = (resource) => {
  if (!resource) {
    return false;
  }
  switch (resource.__typename) {
    case 'Variation':
      return !!resource.parentVariationId || !!resource.parentVariation?.id || resource.components.length > 0;
    case 'Layer':
      return resource.type === 'variation_component';
    default:
      break;
  }
  return false;
}

export const getVariationsWithComponents = (combination) => {
  const variations = combination.flatMap(variation => [variation, ...(variation.components || [])]).filter(v => v)
  return sortVariationsByLayerId(variations);
}

export const sortVariationsByLayerId = (variations) => variations.sort((variationA, variationB) => variationA.layer.state.position - variationB.layer.state.position);

export const isSuperSet = (source, target) => target.every(v => source.includes(v));
export const getFileWithCacheBreak = (variation) => {
  // Variation components may not have a file yet
  if (variation.file) {
    return {
      ...variation.file,
      url: `${variation.file.url}?cache_id=${variation.id}`
    }
  }
  return null;
}