import { Box, Paper } from '@mui/material';

import React from 'react';
import { ScrollableBox } from '@components/Layout';

const RightColumn = ({ sx = {}, children }) =>
  <Box sx={{
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    pr: 1,
    ...sx
  }}>
    {children}
  </Box>

const SidePanel = ({ children }) =>
  <Paper elevation={1}>
    <ScrollableBox sx={{ minWidth: 350, width: 350, ml: 1 }}>
      {children}
    </ScrollableBox>
  </Paper>

RightColumn.SidePanel = SidePanel

export default RightColumn;