import { gql } from '@apollo/client';

const GENERATED_COLLECTION_FIELDS = gql`
  fragment GeneratedCollectionFields on GeneratedCollection {
    id
    collectionId
    generationStatus
    tokensMetadataUrl
    collectionMetadataUrl
    galleryBaseUrl
    jobSucceededAt
    externalId
  }
`;

const GENERATED_COLLECTION_DETAILS = gql`
  query GeneratedCollection($generatedCollectionId: ID!) {
    generatedCollection(generatedCollectionId: $generatedCollectionId) {
      ...GeneratedCollectionFields
    }
  }
  ${GENERATED_COLLECTION_FIELDS}
`;

export { GENERATED_COLLECTION_FIELDS, GENERATED_COLLECTION_DETAILS };
