import { Box } from '@mui/material';
import React from 'react';

const WindowSpace = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      {children}
    </Box>
  );
};

export default WindowSpace;
