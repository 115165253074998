import { LAYER_FIELDS } from '@queries/layers';
import { gql } from '@apollo/client';

const SUBCOLLECTION_FIELDS = gql`
  fragment SubcollectionFields on Subcollection {
    id
    name
    collectionId
    state {
      weight
    }
    layers {
      ...LayerFields
    }
  }
  ${LAYER_FIELDS}
`;

export { SUBCOLLECTION_FIELDS };
