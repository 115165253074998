import { INVITATION_FIELDS } from '@queries/invitations';
import { gql } from '@apollo/client';

const COLLABORATOR_INVITED = gql`
  subscription OnCollaboratedInvited($userId: ID!) {
    collaboratorInvited(userId: $userId) {
      ...InvitationFields
    }
  }
  ${INVITATION_FIELDS}
`;

export { COLLABORATOR_INVITED }