import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';

import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserContext from '@contexts/UserContext';
import { ellipseAddress } from '@lib/helper'
import logo from '@images/logo.png';

export default function MenuAppBar() {
  const {
    connected,
    ensName,
    walletAddress,
    connectWallet,
    disconnectWallet,
    authWalletAddress
  } = useContext(UserContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleLazyAuth = () => {
    const walletAddress = prompt("Please enter your wallet address");
    if (walletAddress) {
      authWalletAddress(walletAddress, 'this-doesnt-matter')
    }
  }

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}>
            <img src={logo} width={200} />
          </Typography>
          <Button href="https://gold-error-e2d.notion.site/GENGEN-Knowledge-Base-03640dfcd1bf4f299f7ed7e2f25b810f" target="_blank" sx={{ mr: 1 }}>Help</Button>
          {!connected && <Button variant="outlined" onClick={connectWallet} sx={{ mr: 1 }}>Connect Wallet</Button>}
          {!connected && <Button variant="outlined" onClick={handleLazyAuth}>Lazy</Button>}
          {connected && (
            <div>
              <Button variant="outlined" onClick={handleMenu}>{ensName || ellipseAddress(walletAddress)}</Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={disconnectWallet}>Disconnect</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
