import { USER_FIELDS } from '@queries/users';
import { gql } from '@apollo/client';

const COLLABORATOR_FIELDS = gql`
  fragment CollaboratorFields on Collaborator {
    id
    user {
      ...UserFields
    }
    role
  }
  ${USER_FIELDS}
`;

export { COLLABORATOR_FIELDS };
