import { COMMON_LAYER_STATE_FIELDS } from '@queries/layer-states';
import { gql } from '@apollo/client';

// Can't leverage GraphQL Fragments for self-references
const COMMON_VARIATION_FIELDS = `
  id
  name
  layer {
    id
    type
    name
    state {
      ${COMMON_LAYER_STATE_FIELDS}
    }
  }
  file {
    lastModified
    mimeType
    name
    size
    url
  }
  state {
    weight
  }
`
const VARIATION_FIELDS = gql`
  fragment VariationFields on Variation {
    ${COMMON_VARIATION_FIELDS}
    parentVariation {
      ${COMMON_VARIATION_FIELDS}
      components {
        ${COMMON_VARIATION_FIELDS}
      }
    }
    components {
      ${COMMON_VARIATION_FIELDS}
    }
  }
`;

const CREATE_VARIATION_FIELDS = gql`
  fragment CreateVariationFields on Variation {
    id
    name
    file {
      lastModified
      mimeType
      name
      size
      url
    }
  }
`;

export { VARIATION_FIELDS, CREATE_VARIATION_FIELDS };
