import 'simplebar/dist/simplebar.min.css';

import { ACCEPT_INVITATION, DECLINE_INVITATION } from '@mutations/invitations';
import { Box, Button, Paper, Skeleton, Typography } from '@mui/material';
import { UserConsumer, UserProvider } from '@contexts/UserContext';
import { ellipseAddress, uniqueItems } from '@lib/helper'
import { useMutation, useQuery } from '@apollo/client';

import AppBar from '@components/AppBar';
import { COLLABORATOR_INVITED } from '@subscriptions/invitations';
import { COLLECTIONS_LIGHT } from '@queries/collections';
import { INVITATIONS } from '@queries/invitations';
import React from 'react';
import { ScrollableBox } from '@components/Layout';
import { USER } from '@queries/users';

const Invitation = ({ invitation }) => {
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION, {
    refetchQueries: [
      COLLECTIONS_LIGHT,
      'collections-light'
    ],
  });
  const [declineInvitation] = useMutation(DECLINE_INVITATION);
  return (
    <Paper elevation={2} sx={{ p: 2, mb: 1 }} key={invitation.id}>
      <Typography component="div" sx={{ mb: 2 }}>
        <Typography component="span" sx={{ fontWeight: 600 }}>{ellipseAddress(invitation.inviter.walletAddress)}</Typography> invited you to join their <Typography component="span" sx={{ fontWeight: 600 }}>{invitation.collection.name}</Typography> Collection as a <Typography component="span" sx={{ fontWeight: 600, textTransform: 'capitalize' }}>{invitation.role}</Typography>.
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          size="small"
          sx={{ mr: 1 }}
          onClick={() => acceptInvitation({
            variables: {
              invitationId: invitation.id
            },
            update: (cache, { data: { declineInvitation } }) => {
              cache.evict({ id: cache.identify({ id: invitation.id, __typename: 'Invitation' }) });
              cache.gc();
            },
            optimisticResponse: {
              declineInvitation: {
                __typename: "Invitation",
                id: invitation.id
              }
            }
          })}>
          Accept
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={() => declineInvitation({
            variables: {
              invitationId: invitation.id
            },
            update: (cache, { data: { declineInvitation } }) => {
              cache.evict({ id: cache.identify({ id: invitation.id, __typename: 'Invitation' }) });
              cache.gc();
            },
            optimisticResponse: {
              declineInvitation: {
                __typename: "Invitation",
                id: invitation.id
              }
            }
          })}>
          Decline
        </Button>
      </Box>
    </Paper>)
}

const Invitations = ({ currentUser }) => {
  const { loading, error, data, subscribeToMore } = useQuery(INVITATIONS);
  if (currentUser) {
    subscribeToMore({
      document: COLLABORATOR_INVITED,
      variables: {
        userId: currentUser.id
      },
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) return previousData;
        const invitation = subscriptionData.data.collaboratorInvited;

        return Object.assign({}, previousData, {
          invitations: uniqueItems([...previousData.invitations, invitation], 'id')
        });
      },
      onError: (err) => console.error('err', err),
    });
  }


  return (
    <Paper elevation={1} sx={{ m: 1, p: 2 }}>
      <ScrollableBox sx={{ width: 350 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Invitations
        </Typography>
        {loading
          ? <Skeleton
            variant="rectangular"
            height={120}
            sx={{ borderRadius: 1, bgcolor: 'grey.900' }} />
          : data?.invitations.length > 0
            ? data.invitations.map(invitation => <Invitation key={invitation.id} invitation={invitation} />)
            : <Box sx={{ pt: 1 }}>
              You have no invitations right now.
            </Box>
        }
      </ScrollableBox>
    </Paper>
  )
}

const LobbyLayout = ({ children }) => {
  const { loading, error, data } = useQuery(USER);
  return (
    <UserProvider currentUser={data?.user}>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <AppBar />
        <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <ScrollableBox sx={{ flexGrow: 1 }}>
            {children}
          </ScrollableBox>
          <UserConsumer>
            {({ currentUser }) => <Invitations currentUser={currentUser} />}
          </UserConsumer>
        </Box>
      </Box>
    </UserProvider>
  )
};

export default LobbyLayout;
