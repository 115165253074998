import React, { useState } from 'react';
import { deleteAccessToken, deleteUserState, getUserState, storeAccessToken, storeUserState } from '@lib/persist';
import { fetchAccessToken, fetchNonce } from '@lib/auth';

import Web3Modal from "web3modal";
import { ethers } from "ethers";
import { navigate } from 'gatsby';

const UserContext = React.createContext({});

export default UserContext;

export const UserProvider = ({ children, currentUser }) => {
  // TODO: Refactor this to use currentUser

  const persistedUserState = getUserState();
  const [walletAddress, setWalletAddress] = useState(persistedUserState?.walletAddress);
  const [ensName, setEnsName] = useState(persistedUserState?.ensName);
  const [connected, setConnected] = useState(persistedUserState?.isConnected || false);

  // const persistUserState = ({ accessToken, ...rest }) => {
  //   storeAccessToken(accessToken)
  //   storeUserState(rest)
  // }

  const clearUserState = () => {
    deleteUserState();
    deleteAccessToken();
  }

  const authWalletAddress = async (walletAddress, signature) =>
    fetchAccessToken(walletAddress, signature).then((accessToken) => storeAccessToken(accessToken)).then(() => walletAddress)

  const onAuthSuccess = (walletAddress) => {
    setWalletAddress(walletAddress);
    setConnected(true);
    storeUserState({
      walletAddress,
      isConnected: true,
      ensName
    })
    navigate('/collections')
  }

  const connectWallet = async () => {
    const providerOptions = {};

    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions // required
    });

    try {
      const instance = await web3Modal.connect();

      const provider = new ethers.providers.Web3Provider(instance);
      // Subscribe to accounts change
      provider?.on("accountsChanged", (accounts) => {
        console.log(accounts);
      });

      // Subscribe to chainId change
      provider?.on("chainChanged", (chainId) => {
        console.log(chainId);
      });

      // Subscribe to provider connection
      provider?.on("connect", (info) => {
        console.log(info);
      });

      // Subscribe to provider disconnection
      provider?.on("disconnect", (error) => {
        console.log(error);
      });

      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();
      const nonce = await fetchNonce(walletAddress);
      const signature = await signer.signMessage(nonce).catch(error => console.log("Sign Error", error));
      if (signature) {
        await authWalletAddress(walletAddress, signature)
        const ensName = await provider.lookupAddress(walletAddress);
        setEnsName(ensName);
        onAuthSuccess(walletAddress);
      }
    } catch (error) {
      switch (error.message) {
        case 'User Rejected':
          console.log("Please unlock your wallet")
          return;
        default:
          console.log(error.message)
          return;
      }
    }
  }

  const disconnectWallet = async () => {
    setConnected(false);
    clearUserState()
    navigate('/')
  }
  const userContext = {
    currentUser,
    ensName,
    walletAddress,
    connected,
    connectWallet,
    disconnectWallet,
    // TODO: Remove this if we ever go to production. This is lazy auth for soyjoy.
    authWalletAddress: (...args) => authWalletAddress(...args).then(onAuthSuccess)
  };

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
};

export const { Consumer: UserConsumer } = UserContext;
