import { INVITATION_FIELDS } from '@queries/invitations';
import { gql } from '@apollo/client';

const INVITE_COLLABORATOR = gql`
  mutation InviteCollaborator(
    $collectionId: ID!
    $provider: String,
    $role: String!,
    $walletAddress: String!,
  ) {
    inviteCollaborator(
      collectionId: $collectionId,
      provider: $provider,
      role: $role,
      walletAddress: $walletAddress,
    ) {
      ...InvitationFields
    }
  }
  ${INVITATION_FIELDS}
`;

const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($invitationId: ID!) {
    acceptInvitation(invitationId: $invitationId) {
      id
    }
  }
`;

const DECLINE_INVITATION = gql`
  mutation DeclineInvitation($invitationId: ID!) {
    declineInvitation(invitationId: $invitationId) {
      id
    }
  }
`;

export {
  INVITE_COLLABORATOR,
  ACCEPT_INVITATION,
  DECLINE_INVITATION
};
