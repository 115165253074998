import { LAYER_STATE_FIELDS } from '@queries/layer-states';
import { VARIATION_FIELDS } from '@queries/variations';
import { gql } from '@apollo/client';

const LAYER_FIELDS = gql`
  fragment LayerFields on Layer {
    id
    name
    type
    subcollectionId
    state {
      ...LayerStateFields
    }
    variations {
      ...VariationFields
    }
  }

  ${VARIATION_FIELDS}
  ${LAYER_STATE_FIELDS}
`;

export { LAYER_FIELDS };
