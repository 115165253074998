import { USER_FIELDS } from '@queries/users';
import { gql } from '@apollo/client';

const INVITATION_FIELDS = gql`
  fragment InvitationFields on Invitation {
    id
    collection {
      id
      name
    }
    inviter {
      ...UserFields
    }
    invitee {
      ...UserFields
    }
    invitationStatus
    role
  }
  ${USER_FIELDS}
`;

const INVITATIONS = gql`
  query {
    invitations {
      ...InvitationFields
    }
  }
  ${INVITATION_FIELDS}
`;

export { INVITATION_FIELDS, INVITATIONS };
