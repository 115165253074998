import { gql } from '@apollo/client';

const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    walletAddress
  }
`;

const USER = gql`
  query {
    user {
      ...UserFields
    }
  }
  ${USER_FIELDS}
`;

export { USER, USER_FIELDS };
