import { gql } from '@apollo/client';

const COMMON_LAYER_STATE_FIELDS = `
  clippingMask
  depth
  parentId
  visibility
  position
  weight
  required
`
const LAYER_STATE_FIELDS = gql`
  fragment LayerStateFields on LayerState {
    ${COMMON_LAYER_STATE_FIELDS}
  }
`;

export { COMMON_LAYER_STATE_FIELDS, LAYER_STATE_FIELDS };