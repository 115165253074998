
export const fetchAccessToken = async (address, signature) => {
    const data = {
        wallet_address: address,
        provider: "ethereum",
        signature
    }

    const requestParams = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }

    const response = await fetch(`${process.env.GATSBY_GRAPHQL_URI}/auth/verify`, requestParams)
        .then(response => response.json())

    const { data: { access_token: accessToken } } = response;

    return accessToken;
}

export const fetchNonce = async (address) => {
    const response = await fetch(`${process.env.GATSBY_GRAPHQL_URI}/auth/nonce?wallet_address=${address}&provider=ethereum`)
        .then(response => response.json())
    const { data: { nonce } } = response;
    return nonce;
}

