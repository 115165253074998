import { COLLABORATOR_FIELDS } from '@queries/collaborators';
import { GENERATED_COLLECTION_FIELDS } from '@queries/generated-collections';
import { INVITATION_FIELDS } from '@queries/invitations';
import { SUBCOLLECTION_FIELDS } from '@queries/subcollections';
import { gql } from '@apollo/client';

const COLLECTION_FIELDS = gql`
  fragment CollectionFields on Collection {
    id
    name
    isSharedCollection
    owner {
      walletAddress
    }
    state {
      title
      description
      imageWidth
      imageHeight
      size
    }
    invitations {
      ...InvitationFields
    }
    collaborators {
      ...CollaboratorFields
    }
    subcollections {
      ...SubcollectionFields
    }
    generatedCollections {
      ...GeneratedCollectionFields
    }
  }
  ${SUBCOLLECTION_FIELDS}
  ${GENERATED_COLLECTION_FIELDS}
  ${COLLABORATOR_FIELDS}
  ${INVITATION_FIELDS}
`;

const COLLECTION_FIELDS_LIGHT = gql`
  fragment CollectionFields on Collection {
    id
    name
    isSharedCollection
    owner {
      walletAddress
    }
    state {
      title
      description
      imageWidth
      imageHeight
      size
    }
    invitations {
      ...InvitationFields
    }
    collaborators {
      ...CollaboratorFields
    }
  }
  ${COLLABORATOR_FIELDS}
  ${INVITATION_FIELDS}
`;

const COLLECTIONS = gql`
  query {
    collections {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const COLLECTION_DETAILS = gql`
  query collection($id: ID!) {
    collection(id: $id) {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS}
`;

const COLLECTIONS_LIGHT = gql`
  query {
    collections {
      ...CollectionFields
    }
  }
  ${COLLECTION_FIELDS_LIGHT}
`;

export { COLLECTIONS_LIGHT, COLLECTIONS, COLLECTION_DETAILS, COLLECTION_FIELDS };
